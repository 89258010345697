import { AdjustBalancePageProps } from '@features/bot/pages/AdjustBalancePage/interface'
import { Form, Formik } from 'formik'
import InputField from '@components/form/InputField/InputField'
import OutlinedButton from '@components/buttons/OutlinedButton'
import PrimaryButton from '@components/buttons/PrimaryButton'
import withAdjustBalancePage from './withAdjustBalancePage'

const AdjustBalancePage = ({
  handleOnClear,
  handleOnAdjust,
  initialValue,
  validationSchema,
  isLoading,
}: AdjustBalancePageProps) => {
  return (
    <div className='max-w-[700px] mx-auto px-[17px] desktop:mt-2 mb-9'>
      <Formik
        initialValues={initialValue}
        onSubmit={handleOnAdjust}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, isValid, touched }) => {
          return (
            <Form>
              <div className='flex flex-col gap-y-[16px]'>
                <InputField
                  name='balance'
                  label='Add more USDT'
                  type='number'
                  placeholder='Input your USDT amount that you want to add (Maximum 10,000 USDT/time)'
                  required
                  showErrorMessage
                  disabled={isLoading}
                />
              </div>
              <div className='flex gap-x-4'>
                <OutlinedButton
                  type='button'
                  className='w-full mt-[40px] rounded-[18px]'
                  disabled={!values.balance || isLoading}
                  onClick={() => handleOnClear(setFieldValue)}
                >
                  Cancel
                </OutlinedButton>
                <PrimaryButton
                  type='submit'
                  className='w-full mt-[40px] rounded-[18px]'
                  disabled={(dirty && !isValid) || !values.balance || isLoading}
                >
                  {isLoading ? 'Loading...' : 'Add'}
                </PrimaryButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const WrappedComponent = withAdjustBalancePage(AdjustBalancePage)
export default WrappedComponent
