import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const increaseExchangeWalletAmountApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    increaseExchangeWalletAmount: builder.mutation<
      boolean,
      { marketType: BotMarketType; increaseAmount: number }
    >({
      query: input => ({
        operationName: 'IncreaseExchangeWalletAmount',
        document: gql`
          mutation IncreaseExchangeWalletAmount(
            $input: IncreaseExchangeWalletAmountInput!
          ) {
            increaseExchangeWalletAmount(
              increaseExchangeWalletAmountInput: $input
            )
          }
        `,
        variables: {
          input,
        },
      }),
    }),
  }),
})

export const { useIncreaseExchangeWalletAmountMutation } =
  increaseExchangeWalletAmountApi
