import React, { useState } from 'react'
import {
  AdjustBalanceForm,
  AdjustBalancePageProps,
} from '@features/bot/pages/AdjustBalancePage/interface'
import * as Yup from 'yup'
import { useIncreaseExchangeWalletAmountMutation } from '@client/mainClient/collections/IncreaseExchangeWalletAmountClientAction'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { toast } from 'react-toastify'
import { exchangeApi } from '@client/client'
import { useDispatch } from 'react-redux'

const withAdjustBalancePage = (Component: React.FC<AdjustBalancePageProps>) => {
  const Hoc = () => {
    const validationSchema = Yup.object().shape({
      balance: Yup.number().min(1).max(10000).required(),
    })
    const initialValue: AdjustBalanceForm = { balance: '' }

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { botMarketType } = useBotMarketType()
    const [increaseExchangeWalletAmount] =
      useIncreaseExchangeWalletAmountMutation()

    const handleOnAdjust = async ({ balance }) => {
      setIsLoading(true)
      try {
        const res = await increaseExchangeWalletAmount({
          increaseAmount: balance,
          marketType: botMarketType,
        }).unwrap()
        dispatch(exchangeApi.util.invalidateTags([{ type: 'WalletBalance' }]))
        toast.success('Adjust balance successful')
      } catch (ex) {
      } finally {
        setIsLoading(false)
      }
    }
    const handleOnClear = setFieldValue => {
      setFieldValue('balance', 0)
    }
    const newProps: AdjustBalancePageProps = {
      validationSchema,
      initialValue,
      handleOnAdjust,
      handleOnClear,
      isLoading,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withAdjustBalancePage
