import React from 'react'
import { useNavigate } from 'react-router-dom'
import BotStatus from '@features/bot/components/BotStatus/BotStatus'
import { GoToTopButton } from '@components/GoToTopButton'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { BotMarketType } from '@interfaces/BotMarketEnum'

import { PenSlimIcon } from '@components/Icons/Icons'
import { useResponsive } from '@hooks/useResponsive'
import withBotDashboardPage from './withBotDashboardPage'
import { ContractDashboardPageProps } from './interface'

const BotDashboardPage = ({
  botMarketType,
  assetValue,
  refetchContractualAsset,
  walletUsdtBalance,
  openErrorCaseModal,
}: ContractDashboardPageProps) => {
  const { isMobile } = useResponsive()
  const navigate = useNavigate()
  return (
    <div className='max-w-[700px] mx-auto pb-[58px]'>
      {/* Header */}
      <div className='flex space-x-3 px-[15px] pt-[24px] mb-[25px]'>
        {/* Contract */}
        <div className='flex flex-col space-y-2'>
          <h1 className='text-base font-bold'>
            {botMarketType === BotMarketType.FUTURE ? 'Contract' : 'Spot'}
          </h1>
          <div className='flex flex-1 text-xs items-center'>
            <span>
              {botMarketType === BotMarketType.FUTURE
                ? 'Margin Balance (USDT):'
                : 'Spot Balance (USDT):'}{' '}
            </span>
            <span className='font-bold'>
              {walletUsdtBalance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              })}
            </span>
            {!isMobile && (
              <span
                className='ml-2 underline flex items-center cursor-pointer'
                onClick={() =>
                  navigate(
                    botMarketType === BotMarketType.FUTURE
                      ? ContractRoutesPath.adjustUSDT.absolutePath
                      : SpotRoutesPath.adjustUSDT.absolutePath
                  )
                }
              >
                <PenSlimIcon className='text-secondary' />
                <span>Adjust USDT balance</span>
              </span>
            )}
          </div>
          {isMobile && (
            <div className='flex flex-1 text-xs items-center'>
              <span
                className='underline flex items-center cursor-pointer'
                onClick={() =>
                  navigate(
                    botMarketType === BotMarketType.FUTURE
                      ? ContractRoutesPath.adjustUSDT.absolutePath
                      : SpotRoutesPath.adjustUSDT.absolutePath
                  )
                }
              >
                <span>Adjust USDT balance</span>
                <PenSlimIcon className='text-secondary' />
              </span>
            </div>
          )}

          <span className='text-xs'>
            {botMarketType === BotMarketType.FUTURE
              ? 'Contractual Asset (USDT)'
              : 'Bot holding (USDT)'}
            :{' '}
            <span className='font-bold'>
              {assetValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              })}
            </span>
          </span>
        </div>
        {/* divider */}
        <div className='flex-1' />
        {/* Create bot */}
        <div>
          <PrimaryButton
            type='button'
            className='h-[32px] py-[5px] px-[16px] rounded-md'
            onClick={() =>
              navigate(
                botMarketType === BotMarketType.FUTURE
                  ? ContractRoutesPath.createContractMenu.absolutePath
                  : SpotRoutesPath.createContractMenu.absolutePath
              )
            }
            data-test-id='create-bot-btn'
          >
            Create bot
          </PrimaryButton>

          <div
            className='text-xs text-right mt-3 cursor-pointer text-gray-400'
            onClick={openErrorCaseModal}
          >
            <span className='border-b'>Find error cases</span>
          </div>
        </div>
      </div>
      <BotStatus refetchContractualAsset={refetchContractualAsset} />

      <GoToTopButton className='desktop:hidden bottom-[72px]' />
    </div>
  )
}

export default withBotDashboardPage(BotDashboardPage)
