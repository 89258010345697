import { Outlet } from 'react-router-dom'
import { AppLayoutWithActivateGuard } from '@components/AppLayout/AppLayout'
import CreateBotPage from '@features/bot/pages/CreateBotPage/CreateBotPage'
import { spotPath, SpotRoutesPath } from '@config/Router'
import ManageStrategy from '@features/bot/pages/ManageStrategy/ManageStrategy'
import BotDashboardPage from '@features/bot/pages/BotDashboardPage/BotDashboardPage'
import CreateBotMenuPage from '@features/bot/pages/CreateBotMenuPage/CreateBotMenuPage'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { BotTypeContextProvider } from '@features/bot/components/BotTypeContext/BotTypeContext'
import AdjustBalancePage from '@features/bot/pages/AdjustBalancePage/AdjustBalancePage'
import BotPage from './pages/BotPage/BotPage'
import BotLayout from './components/BotLayout/BotLayout'

export const SpotRoutes = {
  path: spotPath,
  element: <Outlet />,
  children: [
    {
      path: SpotRoutesPath.dashboard.path,
      element: (
        <AppLayoutWithActivateGuard>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <BotDashboardPage key='BOT_DASHBOARD_SPOT' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.createContractMenu.path,
      element: (
        <AppLayoutWithActivateGuard title='Create'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotMenuPage />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.createBot.path,
      element: (
        <AppLayoutWithActivateGuard title='Create bot'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotPage mode='bot' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    // {
    //   path: SpotRoutesPath.createGridBot.path,
    //   element: (
    //     <AppLayoutWithActivateGuard>
    //       <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
    //         <CreateGridBotPage mode='bot' />
    //       </BotTypeContextProvider>
    //     </AppLayoutWithActivateGuard>
    //   ),
    // },
    {
      path: SpotRoutesPath.bot.path,
      element: (
        <BotLayout title='Bot detail'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <BotPage />
          </BotTypeContextProvider>
        </BotLayout>
      ),
    },
    {
      path: SpotRoutesPath.createStrategy.path,
      element: (
        <AppLayoutWithActivateGuard title='Create strategy'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotPage mode='strategie' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.updateCurrentBot.path,
      element: (
        <AppLayoutWithActivateGuard title='Edit bot'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotPage mode='bot-update-current-strategy' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.updateMainBot.path,
      element: (
        <AppLayoutWithActivateGuard title='Edit bot'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotPage mode='bot-update-main-strategy' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.updateAllBot.path,
      element: (
        <AppLayoutWithActivateGuard title='Edit bot'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotPage mode='bot-update-all-strategy' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    // {
    //   path: SpotRoutesPath.copyByBot.path,
    //   element: (
    //     <AppLayoutWithActivateGuard>
    //       <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
    //         <CreateBotPage mode='copy-by' />
    //       </BotTypeContextProvider>
    //     </AppLayoutWithActivateGuard>
    //   ),
    // },
    // {
    //   path: SpotRoutesPath.copyBySymbol.path,
    //   element: (
    //     <AppLayoutWithActivateGuard title='Create bot (Copy symbol)'>
    //       <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
    //         <CreateBotPage mode='copy-by-symbol' />
    //       </BotTypeContextProvider>
    //     </AppLayoutWithActivateGuard>
    //   ),
    // },
    // {
    //   path: SpotRoutesPath.copyByPersonal.path,
    //   element: (
    //     <AppLayoutWithActivateGuard title='Create bot (Copy personal)'>
    //       <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
    //         <CreateBotPage mode='copy-by-personal' />
    //       </BotTypeContextProvider>
    //     </AppLayoutWithActivateGuard>
    //   ),
    // },
    {
      path: SpotRoutesPath.manageStrategy.path,
      element: (
        <AppLayoutWithActivateGuard title='Manage strategy'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <ManageStrategy />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.updateStrategy.path,
      element: (
        <AppLayoutWithActivateGuard title='Edit strategy'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <CreateBotPage mode='strategy-update' />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: SpotRoutesPath.adjustUSDT.path,
      element: (
        <AppLayoutWithActivateGuard title='Adjust USDT balance (SPOT)'>
          <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
            <AdjustBalancePage />
          </BotTypeContextProvider>
        </AppLayoutWithActivateGuard>
      ),
    },
    // {
    //   path: ContractRoutesPath.createEasyTradeOwnerSelect.path,
    //   element: (
    //     <AppLayoutWithActivateGuard title='Easy trade'>
    //       <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
    //         <CreateEasyTradeOwnerSelectPage />
    //       </BotTypeContextProvider>
    //     </AppLayoutWithActivateGuard>
    //   ),
    // },
    // {
    //   path: ContractRoutesPath.createEasyTradeConfig.path,
    //   element: (
    //     <AppLayoutWithActivateGuard title='Easy trade'>
    //       <BotTypeContextProvider botMarketType={BotMarketType.SPOT}>
    //         <CreateEasyBotPage />
    //       </BotTypeContextProvider>
    //     </AppLayoutWithActivateGuard>
    //   ),
    // },
  ],
}
